"use strict";

export class Selection {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al cambiar el estado de un usuario */
    changeUser() {
        document.querySelectorAll('.changeUser').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'></p>", "warning", "<p class='m-0 text-center'>¿Realmente desea cambiar el estado de este usuario?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'put',
                            url: `/admin/users/${id}/active`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/users`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha cambiado el estado del usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha cambiado el estado del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha cambiado el estado del usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha cambiado el estado del usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha cambiado el estado del usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha cambiado el estado del usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha cambiado el estado del usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>El usuario seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al seleccionar un título desde el formulario de estudios */
    chooseAcademicTitle() {
        if (
            document.body.contains(document.querySelector('#academic_title_id')) &&
            document.body.contains(document.querySelector('#div_external_academic_title')) &&
            document.body.contains(document.querySelector('#external_academic_title')) &&
            document.body.contains(document.querySelector('#label_external_academic_title'))
        ) {
            let academicTitle = document.querySelector("#academic_title_id");
            let divExternalAcademicTitle = document.querySelector("#div_external_academic_title");
            let externalAcademicTitle = document.querySelector("#external_academic_title");
            let labelExternalAcademicTitle = document.querySelector("#label_external_academic_title");

            try {
                new Selectr(academicTitle);
            } catch (error) {
                console.log(error);
            }

            academicTitle.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Oculta el label para el campo de texto del programa */
                labelExternalAcademicTitle.classList.add('invisible');

                /** Sí se elige 'Otro' */
                if (text == 'OTRO') {
                    /** Muestra el campo de texto para el programa */
                    divExternalAcademicTitle.classList.remove('hidden');
                    divExternalAcademicTitle.classList.add('md:grid');
                    externalAcademicTitle.required = true;

                    /** Sí se elige diferente a 'OTRO' */
                } else {
                    /** Oculta el campo de texto para el programa */
                    divExternalAcademicTitle.classList.remove('md:grid');
                    divExternalAcademicTitle.classList.add('hidden');
                    externalAcademicTitle.required = false;
                    externalAcademicTitle.value = null;
                }
            })
        }
    }

    /** Método que aplica al seleccionar aplicaciones (en aprobación) del listado */
    chooseApplications() {
        if (document.body.contains(document.querySelector('#table_applications'))) {
            let actNumber = document.querySelector('#act_number');
            let divAct = document.querySelector('#div_act');
            let divExtension = document.querySelector('#div_extension');
            let extension_0 = document.querySelector('#is_extension_0');
            let extension_1 = document.querySelector('#is_extension_1');
            let formDateApproved = document.querySelector('#form_date_approved');
            let inputApplications = document.querySelector('#applications');
            let inputEmpirical = document.querySelector('#empirical');
            let inputExtension = document.querySelector("#extension");

            let arraySelected = [];
            let arraySelectedEmpirical = [];
            let arraySelectedPermission = [];
            let countSelected = 0;
            let isEmpirical = 0;
            let isExtension = 0;

            /** Recorre todos los elementos que estan aprobados */
            document.querySelectorAll('.on_aprobation').forEach(item => {
                /** Al dar click en el botón */
                item.addEventListener('click', event => {
                    /** Obtiene el número de consecutivo */
                    let consecutive = item.getAttribute("id");

                    /** Obtiene el trámite de la solicitud */
                    let procedure = item.getAttribute("alt");

                    /** Habilita/deshabilita ciertas clases para indicar en pantalla */
                    item.classList.toggle('mark_to_documentation');
                    item.classList.toggle('text-gray-500');
                    item.classList.toggle('text-green-500');
                    item.classList.toggle('hover:bg-gray-500');
                    item.classList.toggle('hover:bg-green-500');

                    /** Se agrega el consecutivo al arreglo */
                    if (
                        item.classList.contains('mark_to_documentation') &&
                        !arraySelected.includes(consecutive)
                    ) {
                        arraySelected.push(consecutive);

                        /** Si el trámite es permiso de trabajo */
                        if (procedure == 8) {
                            arraySelectedPermission.push(1);
                        }

                        /** Si el trámite es empírico */
                        if (procedure == 10) {
                            arraySelectedEmpirical.push(1);
                        }

                      /** Se quita el consecutivo del arreglo */
                    } else {
                        arraySelected.pop(consecutive);

                        /** Si el trámite es permiso de trabajo */
                        if (procedure == 8) {
                            arraySelectedPermission.pop(1);
                        }

                        /** Si el trámite es empírico */
                        if (procedure == 10) {
                            arraySelectedEmpirical.pop(1);
                        }
                    }

                    /** Cantidad de elementos seleccionados */
                    countSelected = document.querySelectorAll('.mark_to_documentation').length;

                    /** Si la cantidad es superior a 5 se muestra un mensaje */
                    if (countSelected > 5) {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>Por favor elija como máximo 5 solicitudes antes de continuar</p>");
                    }

                    /**
                     * 1. Si la cantidad es menor o igual que 0
                     * 2. Si la cantidad es mayor que 5
                     * se oculta el formulario de envio
                     */
                    if (countSelected <= 0 || countSelected > 5) {
                        formDateApproved.classList.add('hidden');
                        formDateApproved.classList.remove('inline-flex');

                    /** de lo contrario se muestra */
                    } else {
                        formDateApproved.classList.add('inline-flex');
                        formDateApproved.classList.remove('hidden');
                    }

                    /** Si no hay solicitudes de empíricos seleccionadas se oculta el campo de resolución */
                    if (arraySelectedEmpirical.length <= 0) {
                        actNumber.required = false;
                        actNumber.value = null;

                        divAct.classList.remove('md:grid');
                        divAct.classList.add('hidden');
                        isEmpirical = 0;

                    /** de lo contrario se muestra el campo de resolución */
                    } else {
                        actNumber.required = true;

                        divAct.classList.remove('hidden');
                        divAct.classList.add('md:grid');
                        isEmpirical = 1;
                    }

                    /** Si no hay solicitudes de permisos de trabajo seleccionadas se oculta el campo de extensión */
                    if (arraySelectedPermission.length <= 0) {
                        extension_0.required = false;
                        extension_0.checked = false;

                        extension_1.required = false;
                        extension_1.checked = false;

                        divExtension.classList.remove('md:grid');
                        divExtension.classList.add('hidden');
                        isExtension = 0;

                    /** de lo contrario se muestra el campo de extensión */
                    } else {
                        extension_0.required = true;
                        extension_1.required = true;

                        divExtension.classList.remove('hidden');
                        divExtension.classList.add('md:grid');
                        isExtension = 1;
                    }

                    /** Asigna al valor oculto el arreglo de consecutivos */
                    inputApplications.value = arraySelected;

                    /** Asigna al valor oculto dependiendo si hay o no solicitudes de empíricos */
                    inputEmpirical.value = isEmpirical;

                    /** Asigna al valor oculto dependiendo si hay o no solicitudes de permisos de trabajo */
                    inputExtension.value = isExtension;
                })
            })
        }
    }

    /** Método que aplica al seleccionar una universidad/instituto desde el formulario de estudios */
    chooseInstitution() {
        if (
            document.body.contains(document.querySelector('#academic_title_id')) &&
            document.body.contains(document.querySelector('#div_academic_title_id')) &&
            document.body.contains(document.querySelector('#div_external_academic_title')) &&
            document.body.contains(document.querySelector('#div_external_institution')) &&
            document.body.contains(document.querySelector('#external_academic_title')) &&
            document.body.contains(document.querySelector('#external_institution')) &&
            document.body.contains(document.querySelector('#institution_id')) &&
            document.body.contains(document.querySelector('#label_external_academic_title'))
        ) {
            let academicTitle = document.querySelector("#academic_title_id");
            let divAcademicTitle = document.querySelector("#div_academic_title_id");
            let divExternalAcademicTitle = document.querySelector("#div_external_academic_title");
            let divExternalInstitution = document.querySelector("#div_external_institution");
            let externalAcademicTitle = document.querySelector("#external_academic_title");
            let externalInstitution = document.querySelector("#external_institution");
            let institution = document.querySelector("#institution_id");
            let labelExternalAcademicTitle = document.querySelector("#label_external_academic_title");

            institution.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Sí se elige 'Otro' */
                if (text == 'OTRO') {
                    /** Oculta el selector de programas */
                    divAcademicTitle.classList.add('hidden');
                    divAcademicTitle.classList.remove('md:grid');
                    academicTitle.required = false;

                    /** Muestra el campo de texto para el instituto */
                    divExternalInstitution.classList.remove('hidden');
                    divExternalInstitution.classList.add('md:grid');
                    externalInstitution.required = true;

                    /** Muestra el campo de texto para el programa */
                    divExternalAcademicTitle.classList.remove('hidden');
                    divExternalAcademicTitle.classList.add('md:grid');
                    externalAcademicTitle.required = true;

                    /** Muestra el label para el campo de texto del programa */
                    labelExternalAcademicTitle.classList.remove('invisible');

                    /** Sí se elige diferente a 'OTRO' */
                } else {
                    /** Muestra el selector de programas */
                    divAcademicTitle.classList.remove('hidden');
                    divAcademicTitle.classList.add('md:grid');
                    academicTitle.required = true;

                    /**
                     * Si el valor del instituto es nulo
                    */
                    if (externalInstitution.value == '') {
                        /** Oculta el campo de texto para el instituto */
                        divExternalInstitution.classList.remove('md:grid');
                        divExternalInstitution.classList.add('hidden');
                        externalInstitution.required = false;
                        externalInstitution.value = null;
                    }

                    /**
                     * Si el valor del título académico de la lista no es 'OTRO'
                     * Si el valor de título académico externo no es nulo
                    */
                    if (academicTitle.value != '99cf5da9-a4c6-4842-8a2f-435a13c3f04c' && externalAcademicTitle.value == '') {
                        /** Oculta el campo de texto para el programa */
                        divExternalAcademicTitle.classList.remove('md:grid');
                        divExternalAcademicTitle.classList.add('hidden');
                        externalAcademicTitle.required = false;
                        externalAcademicTitle.value = null;
                    }

                    /** Oculta el label para el campo de texto del programa */
                    labelExternalAcademicTitle.classList.add('invisible');
                }
            })
        }
    }

    /** Método que aplica al tener un selector de trámites */
    chooseProcedure() {
        if (
            document.body.contains(document.querySelector('#div_fields')) &&
            document.body.contains(document.querySelector('#div_instructions')) &&
            document.body.contains(document.querySelector('#procedure_id'))
        ) {
            let divFields = document.querySelector("#div_fields");
            let divInstructions = document.querySelector("#div_instructions");
            let procedure = document.querySelector("#procedure_id");
            let spinner = document.querySelector('#spinner');

            try {
                new Selectr(procedure);
            } catch (error) {
                console.log(error);
            }

            procedure.addEventListener('change', (event) => {
                /** Muestra el spinner mientras procesa la petición */
                spinner.classList.remove('hidden');

                /** Valor del procedimiento elegido */
                let procedureValue = procedure.value;

                /** Ruta que retorna los requerimientos del procedimiento */
                let url = `/fields/get/requirements/${procedureValue}`;

                axios({
                    body: JSON.stringify({
                        procedureValue
                    }),
                    method: 'put',
                    url: url
                }).then((response) => {
                    /** Oculta el spinner cuando termina la petición */
                    spinner.classList.add('hidden');

                    /** Muestra las instrucciones */
                    divInstructions.classList.remove('hidden');

                    /** Muestra los requerimientos */
                    divFields.classList.remove('hidden');
                    divFields.innerHTML = response.data;
                }).catch((error) => {
                    let message;

                    switch (error.response.status) {
                        case 400:
                            message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar los requerimientos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 403:
                            message = '<p class="m-0">Acceso denegado.</p>';
                            break;

                        case 404:
                            message = '<p class="m-0">No se pueden mostrar los requerimientos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 405:
                            message = '<p class="m-0">No se pueden mostrar los requerimientos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;

                        case 500:
                            message = '<p class="m-0">No se pueden mostrar los requerimientos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                            break;

                        case 504:
                            message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar los requerimientos, por favor vuelve a intentarlo.</p>';
                            break;

                        case 509:
                            message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar los requerimientos, por favor vuelve a intentarlo.</p>';
                            break;

                        default:
                            message = '<p class="m-0">No se pueden mostrar los requerimientos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                            break;
                    }

                    this.notification.showToasted("alive", "error", message);
                });

                /** Muestra las instrucciones */
                divInstructions.classList.remove('hidden');

                /** Muestra los requerimientos */
                divFields.classList.remove('hidden');
            })
        }
    }

    /** Método que aplica al seleccionar un rol desde el formulario de creación de usuarios */
    chooseRole() {
        if (
            document.body.contains(document.querySelector('#div_fields_applicant')) &&
            document.body.contains(document.querySelector('#role_id'))
        ) {
            let role = document.querySelector("#role_id");
            let divFieldsApplicant = document.querySelector("#div_fields_applicant");

            try {
                new Selectr(role);
            } catch (error) {
                console.log(error);
            }

            role.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Campos adicionales del solicitante */
                let address = document.querySelector("#address");
                let cellPhone = document.querySelector("#cell_phone");
                let dateBirth = document.querySelector("#date_of_birth");
                let dateExpedition = document.querySelector("#date_of_expedition");
                let gender = document.querySelector("#gender_id");
                let landline = document.querySelector("#landline");
                let region = document.querySelector("#region_id");
                let regionExpedition = document.querySelector("#region_expedition_id");

                /** Sí se elige 'Solicitante' */
                if (text == 'Solicitante') {
                    /** Muestra los campos adicicionales */
                    divFieldsApplicant.classList.remove('hidden');
                    divFieldsApplicant.classList.add('md:grid');

                    /** Obliga a diligenciar los campos adicionales requeridos */
                    cellPhone.required = true;
                    dateBirth.required = true;
                    gender.required = true;
                    region.required = true;
                    regionExpedition.required = true;

                    /** Sí se elige diferente a 'Solicitante' */
                } else {
                    /** Oculta los campos adicicionales */
                    divFieldsApplicant.classList.remove('md:grid');
                    divFieldsApplicant.classList.add('hidden');

                    /** Quita el 'required' de los campos adicionales requeridos */
                    cellPhone.required = false;
                    dateBirth.required = false;
                    gender.required = false;
                    region.required = false;
                    regionExpedition.required = false;

                    /** Resetea el valor de los campos adicionales */
                    address.value = null;
                    cellPhone.value = null;
                    dateBirth.value = null;
                    dateExpedition.value = null;
                    landline.value = null;
                }
            })
        }
    }

    /** Método que aplica al seleccionar un rol desde el formulario de creación de usuarios */
    chooseRoleTwo() {
        if (
            document.body.contains(document.querySelector('#div_user_special')) &&
            document.body.contains(document.querySelector('#role_id'))
        ) {
            let role = document.querySelector("#role_id");
            let divUserSpecial = document.querySelector("#div_user_special");

            /** Campos adicionales del 'Presidente' y/o 'Secretario' */
            let profession = document.querySelector("#profession");
            let professionalRegistration = document.querySelector("#professional_registration");
            let signature = document.querySelector("#signature");

            /** Datos dinámicos con el rol actual */
            if (role.value == 6 || role.value == 7) {
                /** Obliga a diligenciar los campos adicionales */
                profession.required = true;
                professionalRegistration.required = true;
            } else {
                /** Quita el 'required' de los campos adicionales */
                profession.required = false;
                professionalRegistration.required = false;
                signature.required = false;
            }

            try {
                new Selectr(role);
            } catch (error) {
                console.log(error);
            }

            /** Datos dinámicos al elegir un rol */
            role.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Sí se elige 'Presidente' y/o 'Secretario' */
                if (text == 'Presidente' || text == 'Secretario') {
                    /** Muestra los campos adicicionales */
                    divUserSpecial.classList.remove('hidden');
                    divUserSpecial.classList.add('md:grid');

                    /** Obliga a diligenciar los campos adicionales requeridos */
                    profession.required = true;
                    professionalRegistration.required = true;
                    signature.required = true;

                    /** Sí se elige diferente a 'Presidente' y/o 'Secretario' */
                } else {
                    /** Oculta los campos adicicionales */
                    divUserSpecial.classList.remove('md:grid');
                    divUserSpecial.classList.add('hidden');

                    /** Quita el 'required' de los campos adicionales requeridos */
                    profession.required = false;
                    professionalRegistration.required = false;
                    signature.required = false;

                    /** Resetea el valor de los campos adicionales */
                    profession.value = null;
                    professionalRegistration.value = null;
                    signature.value = null;
                }
            })
        }
    }

    /** Método que aplica al seleccionar el estado en el formulario de gestión de solicitudes */
    chooseStage() {
        if (
            document.body.contains(document.querySelector('#div_resolution')) &&
            document.body.contains(document.querySelector('#resolution')) &&
            document.body.contains(document.querySelector('#stage_id'))
        ) {
            let divResolution = document.querySelector("#div_resolution");
            let resolution = document.querySelector("#resolution");
            let stage = document.querySelector("#stage_id");

            stage.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let value = optionSelected.selectedOptions[0].value;

                /** Sí se elige 'Pendiente de pago' */
                if (value == 5) {
                    /** Muestra el campo de texto para subir el archivo de resolución de consejo */
                    divResolution.classList.remove('hidden');
                    divResolution.classList.add('md:grid');
                    resolution.required = true;

                    /** Sí se elige diferente a 'Pendiente de pago' */
                } else {
                    /** Oculta el campo de texto para subir el archivo de resolución de consejo */
                    divResolution.classList.remove('md:grid');
                    divResolution.classList.add('hidden');
                    resolution.required = false;
                    resolution.value = null;
                }
            })
        }
    }

    /** Método que aplica al diligenciar el campo '¿Es convalidación?' en los formularios de creación */
    recognitionOnCreate() {
        if (
            document.body.contains(document.querySelector('#form_record')) &&
            document.body.contains(document.querySelector('#section_recognition')) &&
            document.body.contains(document.querySelector('#is_validation_1')) &&
            document.body.contains(document.querySelector('#is_validation_2'))
        ) {
            let date_resolution = document.querySelector("#date_resolution");
            let is_validation = document.querySelectorAll(".is_validation");
            let is_validation_1 = document.querySelector('#is_validation_1');
            let men_document = document.querySelector("#men_document");
            let number_resolution = document.querySelector("#number_resolution");
            let section_recognition = document.querySelector("#section_recognition");
            let validated_degree = document.querySelector("#validated_degree");

            let div_date_resolution = document.querySelector("#div_date_resolution");
            let div_men_document = document.querySelector("#div_men_document");
            let div_number_resolution = document.querySelector("#div_number_resolution");
            let div_validated_degree = document.querySelector("#div_validated_degree");

            /** Comprobación inicial apenas carga la página */
            if (is_validation_1.checked == true) {
                /** Muestra el div de los campos condicionados */
                section_recognition.classList.remove('hidden');

                /** Muestra el control de 'Documento de convalidación emitido MEN' */
                div_men_document.classList.remove('hidden');
                div_men_document.classList.add('md:grid');
                men_document.required = true;

                /** Muestra el control de 'Fecha de la resolución MEN' */
                div_date_resolution.classList.remove('hidden');
                div_date_resolution.classList.add('md:grid');
                date_resolution.required = true;

                /** Muestra el control de 'Número de resolución MEN' */
                div_number_resolution.classList.remove('hidden');
                div_number_resolution.classList.add('md:grid');
                number_resolution.required = true;

                /** Muestra el control de 'Título convalidado' */
                div_validated_degree.classList.remove('hidden');
                div_validated_degree.classList.add('md:grid');
                validated_degree.required = true;
            } else {
                /** Oculta el control de documento de 'Documento de convalidación emitido MEN' */
                div_men_document.classList.add('hidden');
                div_men_document.classList.remove('md:grid');
                men_document.required = false;

                /** Oculta el control de 'Fecha de la resolución MEN' */
                div_date_resolution.classList.add('hidden');
                div_date_resolution.classList.remove('md:grid');
                date_resolution.required = false;

                /** Oculta el control de 'Número de resolución MEN' */
                div_number_resolution.classList.add('hidden');
                div_number_resolution.classList.remove('md:grid');
                number_resolution.required = false;

                /** Oculta el control de 'Título convalidado' */
                div_validated_degree.classList.add('hidden');
                div_validated_degree.classList.remove('md:grid');
                validated_degree.required = false;
            }

            /** Comprobación dinámica que se dispara cuando se interactua con el control */
            is_validation.forEach(item => {
                item.addEventListener('change', (event) => {
                    if (event.target.value == 0) {
                        /** Oculta el div de los campos condicionados */
                        section_recognition.classList.add('hidden');

                        /** Oculta el control de documento de 'Documento de convalidación emitido MEN' */
                        div_men_document.classList.add('hidden');
                        div_men_document.classList.remove('md:grid');
                        men_document.required = false;

                        /** Oculta el control de 'Fecha de la resolución MEN' */
                        div_date_resolution.classList.add('hidden');
                        div_date_resolution.classList.remove('md:grid');
                        date_resolution.required = false;

                        /** Oculta el control de 'Número de resolución MEN' */
                        div_number_resolution.classList.add('hidden');
                        div_number_resolution.classList.remove('md:grid');
                        number_resolution.required = false;

                        /** Oculta el control de 'Título convalidado' */
                        div_validated_degree.classList.add('hidden');
                        div_validated_degree.classList.remove('md:grid');
                        validated_degree.required = false;
                    } else {
                        /** Muestra el div de los campos condicionados */
                        section_recognition.classList.remove('hidden');

                        /** Muestra el control de 'Documento de convalidación emitido MEN' */
                        div_men_document.classList.remove('hidden');
                        div_men_document.classList.add('md:grid');
                        men_document.required = true;

                        /** Muestra el control de 'Fecha de la resolución MEN' */
                        div_date_resolution.classList.remove('hidden');
                        div_date_resolution.classList.add('md:grid');
                        date_resolution.required = true;

                        /** Muestra el control de 'Número de resolución MEN' */
                        div_number_resolution.classList.remove('hidden');
                        div_number_resolution.classList.add('md:grid');
                        number_resolution.required = true;

                        /** Muestra el control de 'Título convalidado' */
                        div_validated_degree.classList.remove('hidden');
                        div_validated_degree.classList.add('md:grid');
                        validated_degree.required = true;
                    }
                })
            })
        }
    }

    /** Método que aplica al diligenciar el campo '¿Es convalidación?' en los formularios de edición */
    recognitionOnEdit() {
        if (
            document.body.contains(document.querySelector('#form_record_edit')) &&
            document.body.contains(document.querySelector('#section_recognition')) &&
            document.body.contains(document.querySelector('#is_validation_1')) &&
            document.body.contains(document.querySelector('#is_validation_2'))
        ) {
            let date_resolution = document.querySelector("#date_resolution");
            let is_validation = document.querySelectorAll(".is_validation");
            let is_validation_1 = document.querySelector('#is_validation_1');
            let men_document = document.querySelector("#men_document");
            let number_resolution = document.querySelector("#number_resolution");
            let section_recognition = document.querySelector("#section_recognition");
            let validated_degree = document.querySelector("#validated_degree");

            let div_date_resolution = document.querySelector("#div_date_resolution");
            let div_men_document = document.querySelector("#div_men_document");
            let div_number_resolution = document.querySelector("#div_number_resolution");
            let div_validated_degree = document.querySelector("#div_validated_degree");

            /** Comprobación inicial apenas carga la página */
            if (is_validation_1.checked == true) {
                /** Muestra el div de los campos condicionados */
                section_recognition.classList.remove('hidden');

                /** Muestra el control de 'Documento de convalidación emitido MEN' */
                div_men_document.classList.remove('hidden');
                div_men_document.classList.add('md:grid');

                /** Muestra el control de 'Fecha de la resolución MEN' */
                div_date_resolution.classList.remove('hidden');
                div_date_resolution.classList.add('md:grid');
                date_resolution.required = true;

                /** Muestra el control de 'Número de resolución MEN' */
                div_number_resolution.classList.remove('hidden');
                div_number_resolution.classList.add('md:grid');
                number_resolution.required = true;

                /** Muestra el control de 'Título convalidado' */
                div_validated_degree.classList.remove('hidden');
                div_validated_degree.classList.add('md:grid');
                validated_degree.required = true;
            } else {
                /** Oculta el control de documento de 'Documento de convalidación emitido MEN' */
                div_men_document.classList.add('hidden');
                div_men_document.classList.remove('md:grid');

                /** Oculta el control de 'Fecha de la resolución MEN' */
                div_date_resolution.classList.add('hidden');
                div_date_resolution.classList.remove('md:grid');
                date_resolution.required = false;

                /** Oculta el control de 'Número de resolución MEN' */
                div_number_resolution.classList.add('hidden');
                div_number_resolution.classList.remove('md:grid');
                number_resolution.required = false;

                /** Oculta el control de 'Título convalidado' */
                div_validated_degree.classList.add('hidden');
                div_validated_degree.classList.remove('md:grid');
                validated_degree.required = false;
            }

            /** Comprobación dinámica que se dispara cuando se interactua con el control */
            is_validation.forEach(item => {
                item.addEventListener('change', (event) => {
                    if (event.target.value == 0) {
                        /** Oculta el div de los campos condicionados */
                        section_recognition.classList.add('hidden');

                        /** Oculta el control de documento de 'Documento de convalidación emitido MEN' */
                        div_men_document.classList.add('hidden');
                        div_men_document.classList.remove('md:grid');
                        men_document.required = false;

                        /** Oculta el control de 'Fecha de la resolución MEN' */
                        div_date_resolution.classList.add('hidden');
                        div_date_resolution.classList.remove('md:grid');
                        date_resolution.required = false;

                        /** Oculta el control de 'Número de resolución MEN' */
                        div_number_resolution.classList.add('hidden');
                        div_number_resolution.classList.remove('md:grid');
                        number_resolution.required = false;

                        /** Oculta el control de 'Título convalidado' */
                        div_validated_degree.classList.add('hidden');
                        div_validated_degree.classList.remove('md:grid');
                        validated_degree.required = false;
                    } else {
                        /** Muestra el div de los campos condicionados */
                        section_recognition.classList.remove('hidden');

                        /** Muestra el control de 'Documento de convalidación emitido MEN' */
                        div_men_document.classList.remove('hidden');
                        div_men_document.classList.add('md:grid');
                        men_document.required = true;

                        /** Muestra el control de 'Fecha de la resolución MEN' */
                        div_date_resolution.classList.remove('hidden');
                        div_date_resolution.classList.add('md:grid');
                        date_resolution.required = true;

                        /** Muestra el control de 'Número de resolución MEN' */
                        div_number_resolution.classList.remove('hidden');
                        div_number_resolution.classList.add('md:grid');
                        number_resolution.required = true;

                        /** Muestra el control de 'Título convalidado' */
                        div_validated_degree.classList.remove('hidden');
                        div_validated_degree.classList.add('md:grid');
                        validated_degree.required = true;
                    }
                })
            })
        }
    }
}
