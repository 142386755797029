"use strict";

export class Elimination {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al eliminar un programa académico */
    destroyAcademicTitle() {
        document.querySelectorAll('.destroyAcademicTitle').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este programa académico?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/academic_titles/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/academic_titles`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el programa académico, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el programa académico porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el programa académico porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el programa académico porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el programa académico, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el programa académico, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el programa académico porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>El programa académico seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una solicitud desde el perfil de un usuario 'Solicitante' */
    destroyApplication() {
        document.querySelectorAll('.destroyApplication').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta solicitud?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/applications/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/home`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la solicitud porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la solicitud porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La solicitud seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una formación académica desde el perfil de un usuario 'Administrador' */
    destroyDegree() {
        document.querySelectorAll('.destroyDegree').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let application_id = attributes[0].replace(" ", "");
                let degree_id = attributes[1].replace(" ", "");

                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta formación académica?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                application_id,
                                degree_id
                            }),
                            method: 'delete',
                            url: `/admin/degrees/${application_id}/${degree_id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/applications/${application_id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La formación académica seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un género */
    destroyGender() {
        document.querySelectorAll('.destroyGender').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este género?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/genders/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/genders`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el género, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el género porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el género porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el género porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el género, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el género, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el género porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>El género seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un género */
    destroyIdentificationType() {
        document.querySelectorAll('.destroyIdentificationType').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este tipo de identificación?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/identification_types/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/identification_types`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el tipo de identificación, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el tipo de identificación porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el tipo de identificación porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el tipo de identificación porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el tipo de identificación, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el tipo de identificación, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el tipo de identificación porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>El tipo de identificación seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }

   /** Método que aplica al eliminar una institución */
    destroyInstitution() {
        document.querySelectorAll('.destroyInstitution').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta institución?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/institutions/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/institutions`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la institución, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la institución porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la institución porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la institución porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la institución, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la institución, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la institución porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La institución seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una experiencia laboral desde el perfil de un usuario 'Administrador' */
    destroyJob() {
        document.querySelectorAll('.destroyJob').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let application_id = attributes[0].replace(" ", "");
                let job_id = attributes[1].replace(" ", "");

                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta experiencia laboral?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                application_id,
                                job_id
                            }),
                            method: 'delete',
                            url: `/admin/jobs/${application_id}/${job_id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/applications/${application_id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La experiencia laboral seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una experiencia laboral desde el perfil de un usuario 'Solicitante' */
    destroyLabor() {
        document.querySelectorAll('.destroyLabor').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let application_id = attributes[0].replace(" ", "");
                let job_id = attributes[1].replace(" ", "");

                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta experiencia laboral?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                application_id,
                                job_id
                            }),
                            method: 'delete',
                            url: `/jobs/${application_id}/${job_id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/applications/${application_id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la experiencia laboral, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la experiencia laboral porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La experiencia laboral seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una transacción */ /***** Revisar */
    destroyPayment() {
        document.querySelectorAll('.destroyPayment').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-justify'>Esta acción es irreversible. Recuerde primero validar con epayco la existencia de esta transacción antes de proceder.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta transacción?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/payments/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => window.location.reload(), 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la transacción, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la transacción porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la transacción porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la transacción porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la transacción, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la transacción, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la transacción porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La transacción seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar el conjunto de documentación requerida para una solicitud desde el perfil de un usuario 'Solicitante' */
    destroyRecords() {
        document.querySelectorAll('.destroyRecords').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar estos datos?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/records/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/applications/${id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado los datos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado los datos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado los datos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado los datos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>Los datos no han sido afectados.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar el conjunto de documentación requerida para una solicitud desde el perfil de un usuario 'Administrador' */
    destroyRecordsAdmin() {
        document.querySelectorAll('.destroyRecordsAdmin').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar estos datos?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/records/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/applications/${id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado los datos porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado los datos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado los datos porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado los datos, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado los datos porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>Los datos no han sido afectados.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar una formación académica desde el perfil de un usuario 'Solicitante' */
    destroyStudy() {
        document.querySelectorAll('.destroyStudy').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let attributes = id.split(".");
                let application_id = attributes[0].replace(" ", "");
                let degree_id = attributes[1].replace(" ", "");

                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar esta formación académica?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                application_id,
                                degree_id
                            }),
                            method: 'delete',
                            url: `/degrees/${application_id}/${degree_id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/applications/${application_id}`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado la formación académica, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado la formación académica porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>La formación académica seleccionada no ha sido afectada.</p>");
                    }
                })
            })
        })
    }

    /** Método que aplica al eliminar un usuario */
    destroyUser() {
        document.querySelectorAll('.destroyUser').forEach(item => {
            item.addEventListener('click', event => {
                let id = item.getAttribute("id");
                let question = this.notification.showSwalConfirm("¡No!", '#E3051B', "Sí, procede", "<p class='m-0 text-center'>Esta acción es irreversible.</p>", "warning", "<p class='m-0 text-center'>¿Realmente desea eliminar este usuario?</p>");

                question.then((result) => {
                    if (result.value) {
                        axios({
                            body: JSON.stringify({
                                id
                            }),
                            method: 'delete',
                            url: `/admin/users/${id}`
                        }).then((response) => {
                            this.notification.showToasted("alive", "success", `<p class='p-0'>${response.data}</p>`);
                            setTimeout(() => location.href = `/admin/users`, 1500);
                        }).catch((error) => {

                            let message;

                            switch (error.response.status) {
                                case 400:
                                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 403:
                                    message = '<p class="m-0">Acceso denegado.</p>';
                                    break;

                                case 404:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 405:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;

                                case 500:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 504:
                                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                case 509:
                                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se ha eliminado el usuario, por favor vuelve a intentarlo.</p>';
                                    break;

                                default:
                                    message = '<p class="m-0">No se ha eliminado el usuario porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                                    break;
                            }

                            this.notification.showToasted("alive", "error", message);
                        });
                    } else {
                        this.notification.showToasted("alive", "error", "<p class='m-0 text-center'>El usuario seleccionado no ha sido afectado.</p>");
                    }
                })
            })
        })
    }
}
