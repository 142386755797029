"use strict";

export class Region {
    constructor (notification) {
        this.notification = notification;
    }

    /** Método que aplica al elegir una región
        div: contenedor
        region: selector
        type: 'country' ó 'child'
     */
    changeRegion(div, region, type, className, idParent, idSon) {
        /** Al elegir una región del selector */
        region.addEventListener('change', (event) => {
            /** Sí el tipo es 'country' y existen selectores hijos se eliminan */
            if (type === 'country' && document.body.contains(document.querySelector('.' + className))) {
                document.querySelectorAll('.' + className).forEach(item => {
                    item.remove();
                })
            }

            /** Sí el tipo es 'child' y existen selectores después del recién creado */
            if (type === 'child' && document.body.contains(div.nextElementSibling)) {
                let next = div.nextElementSibling;

                /** Sí el elemento es un selector con la clase pasada por parámetro se elimina */
                if (next.classList.contains(className)) {
                    next.remove();
                }
            }

            /** Spinner indicativo */
            let spinner = document.querySelector('#spinner');

            /** Muestra el spinner mientras procesa la petición */
            spinner.classList.remove('hidden');

            /** Valor de la región elegida */
            let regionValue = region.value;

            /** Ruta que retorna la cantidad de hijos de la región */
            let url = `/regions/count/${regionValue}`

            axios({
                body: JSON.stringify({
                    regionValue
                }),
                method: 'put',
                url: url
            }).then((response) => {
                /** Oculta el spinner cuando termina la petición */
                spinner.classList.add('hidden');

                /** Sí no tiene hijos cambia los atributos 'id' y 'name' al valor 'idSon' pasado por parámetro */
                if (response.data == 0) {
                    region.id = idSon;
                    region.name = idSon;

                    /** Si tíene hijos cambia los atributos 'id' y 'name' al valor 'idParent' pasado por parámetro */
                } else {
                    region.id = idParent;
                    region.name = idParent;

                    /** Método que crea el selector con los hijos de la región */
                    this.showRegions(div, regionValue, className, idParent, idSon);
                }
            }).catch((error) => {
                let message;

                switch (error.response.status) {
                    case 400:
                        message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 403:
                        message = '<p class="m-0">Acceso denegado.</p>';
                        break;

                    case 404:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 405:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;

                    case 500:
                        message = '<p class="m-0">No se pueden validar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                        break;

                    case 504:
                        message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    case 509:
                        message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden validar las regiones, por favor vuelve a intentarlo.</p>';
                        break;

                    default:
                        message = '<p class="m-0">No se pueden validar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                        break;
                }

                this.notification.showToasted("alive", "error", message);
            });
        })
    }

    /** Método que aplica al tener un selector de país (para el campo país) */
    chooseCountry() {
        if (document.body.contains(document.querySelector('#div_parent'))) {
            let div = document.querySelector('#div_parent');
            let region = document.querySelector("#region_id");

            try {
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir un país */
            this.changeRegion(div, region, 'country', 'div_children', 'parent_id', 'region_id');
        }
    }

    /** Método que aplica al tener un selector de país (para el campo país del documento) */
    chooseCountryTwo() {
        if (document.body.contains(document.querySelector('#div_expedition_parent'))) {
            let div = document.querySelector('#div_expedition_parent');
            let region = document.querySelector("#region_expedition_id");

            try {
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir un país */
            this.changeRegion(div, region, 'country', 'div_expedition_children', 'parent_expedition_id', 'region_expedition_id');
        }
    }

    /** Método que aplica al tener un selector de país desde el formulario de estudios */
    chooseCountryThree() {
        if (
            document.body.contains(document.querySelector('#academic_title_id')) &&
            document.body.contains(document.querySelector('#div_academic_title_id')) &&
            document.body.contains(document.querySelector('#div_external_academic_title')) &&
            document.body.contains(document.querySelector('#div_external_institution')) &&
            document.body.contains(document.querySelector('#div_institution')) &&
            document.body.contains(document.querySelector('#external_academic_title')) &&
            document.body.contains(document.querySelector('#external_institution')) &&
            document.body.contains(document.querySelector('#institution_id')) &&
            document.body.contains(document.querySelector('#label_external_academic_title')) &&
            document.body.contains(document.querySelector('#region_id'))
        ) {
            let academicTitle = document.querySelector("#academic_title_id");
            let divAcademicTitle = document.querySelector("#div_academic_title_id");
            let divExternalAcademicTitle = document.querySelector("#div_external_academic_title");
            let divExternalInstitution = document.querySelector("#div_external_institution");
            let divInstitution = document.querySelector("#div_institution");
            let externalAcademicTitle = document.querySelector("#external_academic_title");
            let externalInstitution = document.querySelector("#external_institution");
            let institution = document.querySelector("#institution_id");
            let labelExternalAcademicTitle = document.querySelector("#label_external_academic_title");
            let region = document.querySelector("#region_id");

            try {
                new Selectr(institution);
                new Selectr(region);
            } catch (error) {
                console.log(error);
            }

            /** Al elegir un país del selector */
            region.addEventListener('change', (event) => {
                let optionSelected = event.target;
                let text = optionSelected.selectedOptions[0].text;

                /** Sí se elige 'Colombia' */
                if (text == '-COLOMBIA') {
                    /** Muestra el selector de institutos */
                    divInstitution.classList.remove('hidden');
                    divInstitution.classList.add('md:grid');
                    institution.required = true;

                    /** Muestra el selector de programas */
                    divAcademicTitle.classList.remove('hidden');
                    divAcademicTitle.classList.add('md:grid');
                    academicTitle.required = true;

                    /** Oculta el campo de texto para el instituto */
                    divExternalInstitution.classList.remove('md:grid');
                    divExternalInstitution.classList.add('hidden');
                    externalInstitution.required = false;
                    externalInstitution.value = null;

                    /** Oculta el campo de texto para el programa */
                    divExternalAcademicTitle.classList.remove('md:grid');
                    divExternalAcademicTitle.classList.add('hidden');
                    externalAcademicTitle.required = false;
                    externalAcademicTitle.value = null;

                    /** Sí se elige otro país */
                } else {
                    /** Oculta el selector de institutos */
                    divInstitution.classList.add('hidden');
                    divInstitution.classList.remove('md:grid');
                    institution.required = false;

                    /** Oculta el selector de programas */
                    divAcademicTitle.classList.add('hidden');
                    divAcademicTitle.classList.remove('md:grid');
                    academicTitle.required = false;

                    /** Muestra el campo de texto para el instituto */
                    divExternalInstitution.classList.remove('hidden');
                    divExternalInstitution.classList.add('md:grid');
                    externalInstitution.required = true;

                    /** Muestra el campo de texto para el programa */
                    divExternalAcademicTitle.classList.remove('hidden');
                    divExternalAcademicTitle.classList.add('md:grid');
                    externalAcademicTitle.required = true;

                    /** Muestra el label para el campo de texto del programa */
                    labelExternalAcademicTitle.classList.remove('invisible');
                }
            })
        }
    }

    /** Método que crea el selector con los hijos de la región */
    showRegions(divParent, region, className, idParent, idSon) {
        /** Ruta que devuelve los hijos de una región */
        let url = `/regions/get/${region}`

        axios({
            body: JSON.stringify({
                region
            }),
            method: 'put',
            url: url
        }).then((response) => {
            /** Contenedor principal */
            let divRegion = document.createElement('div');

            /** Label del selector */
            let label = document.createElement('label');

            /** Contenedor secundario */
            let divSelect = document.createElement('div');

            /** Selector */
            let select = document.createElement('select');

            /** Opción por defecto */
            let optionDefault = document.createElement('option');

            /** Asigna las clases para el contenedor principal */
            divRegion.classList.add("items-center", "w-full", "gap-3", "mb-1", "md:grid", "md:grid-cols-3", className);

            /** Asigna las clases para el label */
            label.classList.add("mb-0", "font-bold", "text-slate-600");

            /** Asigna el texto para el label */
            label.innerText = "";

            /** Asigna las clases para el contenedor secundario */
            divSelect.classList.add("md:col-span-2");

            /** Asigna las clases para el selector */
            select.classList.add("w-full", "px-3", "py-2", "leading-tight", "bg-white", "border", "border-slate-300", "rounded", "appearance-none", "focus:shadow-outline", "focus:bg-slate-200/75", "placeholder:text-gray-600");

            /** Asigna el título para el selector */
            select.title = "Elija la región";

            /** Asigna el atributo 'required' para el selector */
            select.required = "required";

            /** Asigna el atributo 'id' para el selector */
            select.id = idSon;

            /** Asigna el atributo 'name' para el selector */
            select.name = idSon;

            /** Asigna el texto para la opción por defecto */
            optionDefault.innerText = "--Seleccione--";

            /** Asigna el atributo 'value' para la opción por defecto */
            optionDefault.value = "";

            /** Asigna el atributo 'selected' para la opción por defecto */
            optionDefault.selected = "selected";

            /** Asigna el atributo 'disabled' para la opción por defecto */
            optionDefault.disabled = "disabled";

            /** Coloca el contenedor después del selector de país */
            divParent.parentNode.insertBefore(divRegion, divParent.nextSibling);

            /** Coloca el label dentro del contenedor principal */
            divRegion.appendChild(label);

            /** Coloca el contenedor secundario dentro del contenedor principal */
            divRegion.appendChild(divSelect);

            /** Coloca el selector dentro del contenedor secundario */
            divSelect.appendChild(select);

            /** Coloca la opción por defecto dentro del selector */
            select.appendChild(optionDefault);

            /** Recorre el array de regiones hijas */
            response.data.forEach((value) => {
                /** Crea una opción por cada región, asignandole el texto y valor correspondiente */
                let option = document.createElement('option');
                option.innerText = value.name;
                option.value = value.id;

                /** Coloca la opción dentro del selector */
                select.appendChild(option);
            });

            /** Convierte el selector en un campo de búsqueda-selección */
            try {
                new Selectr(select);
            } catch (error) {
                console.log(error);
            }

            /** Método que aplica al elegir una región */
            this.changeRegion(divRegion, select, 'child', className, idParent, idSon);
        }).catch((error) => {
            let message;

            switch (error.response.status) {
                case 400:
                    message = '<p class="m-0">No se ha hecho la petición de forma correcta. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 403:
                    message = '<p class="m-0">Acceso denegado.</p>';
                    break;

                case 404:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 405:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;

                case 500:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque no se ha recibido una respuesta del servidor, por favor vuelve a intentarlo.</p>';
                    break;

                case 504:
                    message = '<p class="m-0">El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                case 509:
                    message = '<p class="m-0">Se ha superado el ancho de banda disponible. No se pueden mostrar las regiones, por favor vuelve a intentarlo.</p>';
                    break;

                default:
                    message = '<p class="m-0">No se pueden mostrar las regiones porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</p>';
                    break;
            }

            this.notification.showToasted("alive", "error", message);
        });
    }
}
